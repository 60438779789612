import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSupabaseClient } from '@supabase/auth-helpers-react';

import Logo from '@/components/icons/logo';
import { useUser } from '@/hooks/use-user';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';
import { PencilIcon } from 'lucide-react';
import BeanCount from './bean-count';

const Navbar = () => {
  const router = useRouter();
  const supabaseClient = useSupabaseClient();
  const { user, userDetails, plan } = useUser();

  const totalBeans =
    Number(userDetails?.credits) + Number(userDetails?.addon_credits) || 0;
  function AuthedNavBar() {
    return (
      <ul className="flex flex-1 justify-end items-center space-x-4">
        <li>
          <Link href="/wizard" aria-label="Create">
            <div className="rounded-md px-4 py-1 bg-gradient-to-r from-slate-500 to-purple-700 text-slate-100 text-sm flex items-center space-x-1">
              <PencilIcon size={16} /> <span>Create</span>
            </div>
          </Link>
        </li>
        {plan && <li className="font-bold text-slate-300">{plan}</li>}
        <li className="flex items-center space-x-1">
          <BeanCount count={totalBeans} />
        </li>
        <li>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Avatar className="cursor-pointer">
                <AvatarImage src={user?.user_metadata.avatar_url} />
                <AvatarFallback>
                  {user?.user_metadata.full_name.split(' ')[0].substr(0, 2)}
                </AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" forceMount>
              <DropdownMenuLabel className="opacity-80">
                My Account
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <Link href="/pricing">
                <DropdownMenuItem className="text-slate-400">
                  Pricing
                </DropdownMenuItem>
              </Link>
              <Link href="/account">
                <DropdownMenuItem>Profile</DropdownMenuItem>
              </Link>
              <DropdownMenuItem
                onClick={async () => {
                  await supabaseClient.auth.signOut();
                  await router.push('/signin');
                }}
              >
                Sign out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </li>
      </ul>
    );
  }

  function UnAuthedNavBar() {
    return (
      <ul className="flex flex-1 justify-end items-center space-x-8">
        <li>
          <Link href="/signin" aria-label="Sign In">
            Sign In
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <nav className="z-99">
      <div>
        <div className="flex justify-between align-center flex-row py-2 px-4 relative">
          {/* Logo */}
          <div className="flex flex-1 items-center space-x-4">
            <Link href="/" aria-label="Logo">
              <Logo />
            </Link>
            {user && router.pathname !== '/' && (
              <Link href="/" aria-label="Dashboard Button">
                <Button variant="ghost">Dashboard</Button>
              </Link>
            )}
          </div>

          {user ? <AuthedNavBar /> : <UnAuthedNavBar />}
        </div>

        <Separator />
      </div>
    </nav>
  );
};

export default Navbar;
