const Logo = ({ className = '', ...props }) => (
  // add gradient to logo
  <svg
    width="80"
    height="30"
    viewBox="0 0 67 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.62 15.46C0.62 18.66 2.66 20.76 5.82 20.76C8.62 20.76 10.66 19.04 10.86 16.52H7.76C7.56 17.5 6.9 18 5.82 18C4.5 18 3.72 17.06 3.72 15.46C3.72 13.92 4.56 12.94 5.86 12.94C6.92 12.94 7.54 13.42 7.76 14.42H10.86C10.66 11.8 8.7 10.18 5.74 10.18C2.74 10.18 0.62 12.38 0.62 15.46ZM16.5867 20.5V13.06H18.4867V10.5H16.5867V7.4H13.5067V10.5H11.6067V13.06H13.5067V20.5H16.5867ZM26.7509 10.5C26.3309 10.4 25.9309 10.36 25.5709 10.36C24.2309 10.36 23.3509 11.02 22.9309 12L22.7709 10.52H19.8709V20.5H22.9509V16.16C22.9509 14.18 24.0109 13.4 25.7709 13.4H26.7509V10.5ZM29.9684 20.48V15.22C29.9684 13.36 31.0484 11.82 33.1284 11.82C34.6284 11.82 35.6884 12.62 35.6884 14.88V20.5H37.0684V14.58C37.0684 12.14 35.9284 10.56 33.3884 10.56C31.7684 10.56 30.5884 11.34 29.9684 12.5V5.92H28.5884V20.48H29.9684ZM43.8789 20.74C46.1589 20.74 47.6589 19.64 48.1789 17.6H46.8589C46.4989 18.88 45.4789 19.56 43.8989 19.56C41.8189 19.56 40.5789 18.2 40.4789 15.84H48.1789V15.16C48.1789 12.42 46.3989 10.56 43.7789 10.56C41.0189 10.56 39.1389 12.64 39.1389 15.66C39.1389 18.7 41.0389 20.74 43.8789 20.74ZM43.7789 11.74C45.5989 11.74 46.7989 12.98 46.7989 14.84H40.5189C40.7589 12.94 41.9589 11.74 43.7789 11.74ZM55.4453 10.7C55.1053 10.62 54.8453 10.6 54.5453 10.6C53.1853 10.6 52.1653 11.28 51.7853 12.32L51.6853 10.82H50.3853V20.5H51.7653V14.98C51.7653 13.14 52.9053 11.94 54.6853 11.94H55.4453V10.7ZM56.2288 15.66C56.2288 18.6 58.3088 20.74 61.1488 20.74C63.9888 20.74 66.0688 18.6 66.0688 15.66C66.0688 12.7 63.9888 10.56 61.1488 10.56C58.3088 10.56 56.2288 12.7 56.2288 15.66ZM57.6288 15.64C57.6288 13.4 59.0688 11.8 61.1488 11.8C63.2088 11.8 64.6688 13.4 64.6688 15.64C64.6688 17.92 63.2088 19.5 61.1488 19.5C59.0688 19.5 57.6288 17.92 57.6288 15.64Z"
      fill="url(#paint0_linear_599_4608)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_599_4608"
        x1="33.5"
        y1="0.5"
        x2="33.5"
        y2="24.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#EBEBEB" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;
