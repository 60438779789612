import { Circle, InfoIcon } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from '@/components/ui/tooltip';
import Beans from '../icons/beans';

const BeanCount = ({
  count,
  fontSize
}: {
  count: number;
  fontSize?: string;
}) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger className={`flex items-center space-x-1 ${fontSize}`}>
        <Beans />
        <span className="font-bold text-slate-300">{count}</span>
        <InfoIcon size={16} className="text-slate-300" />
      </TooltipTrigger>
      <TooltipContent className="bg-slate-700 text-slate-300 rounded-lg w-80 flex flex-col p-4">
        <h1 className="text-xl font-bold text-white">What are Beans?</h1>
        <p className="flex flex-row items-center space-x-2 text-white mt-1">
          Beans are exchanged for custom AI images on the CTRHero platform. Each
          type of image such as backgrounds, characters or text have different
          costs associated!
        </p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default BeanCount;
