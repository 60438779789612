const Beans: React.FC<React.SVGProps<SVGSVGElement>> = (
  { ...props } = {
    width: '16',
    height: '16'
  }
) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_i_602_5193)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.3214 7.63948C15.5545 10.9318 12.2949 14.2652 9.18827 14.1638C4.69471 14.0172 4.52184 8.81754 8.05902 8.73309C9.91415 8.68858 11.1464 6.6501 12.2877 5.61642C14.0929 3.98111 15.1857 5.72389 15.3214 7.63948ZM0.669374 7.98079C0.554126 4.68239 3.93092 1.46542 7.03209 1.6758C11.5175 1.97998 11.5044 7.18246 7.96634 7.14295C6.11054 7.1221 4.80623 9.11608 3.62878 10.1092C1.76619 11.6798 0.736419 9.89986 0.669374 7.98079Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_602_5193"
          x="0.666504"
          y="1.66602"
          width="14.6665"
          height="12.5"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.49217" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.943018 0 0 0 0 0.908333 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_602_5193"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Beans;
