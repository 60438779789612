import Link from 'next/link';

export default function Footer() {
  return (
    <footer className="mx-auto max-w-[1920px] px-6 py-2 mt-8">
      <h6 className="text-center text-white">
        &copy; {new Date().getFullYear()} ctrhero.com. All rights reserved.
      </h6>
      <ul className="flex flex-initial justify-center d:flex-1 space-x-2">
        <li className="py-3 md:py-0 md:pb-4">
          <Link
            href="/"
            className="text-gray-400 hover:text-zinc-200 transition ease-in-out duration-150"
          >
            Privacy Policy
          </Link>
        </li>
        <li className="py-3 md:py-0 md:pb-4">
          <Link
            href="/"
            className="text-gray-400 hover:text-zinc-200 transition ease-in-out duration-150"
          >
            Terms of Use
          </Link>
        </li>
      </ul>
    </footer>
  );
}
